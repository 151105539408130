import React from "react"
import NormalPageHero from "../../components/NormalPageHero"
import * as styles from "../../styles/mining-pools/mining-pools.module.css"

const MiningPoolsHero = () => {
    return (
        <section>
            <div
                className={`pt-[9rem] md:pt-[calc(4rem+150px)] ${styles.miningPoolsHero}`}
            >
                <NormalPageHero
                    titleKey="miningPools.hero.title"
                    subtitleKey="miningPools.hero.p1"
                />
            </div>
        </section>
    )
}

export default MiningPoolsHero
