import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import MiningPoolListItem from "../../components/MiningPools/MiningPoolListItem"
import ContentContainer from "../../templates/ContentContainer"

const sortAlphabetically = (a, b) => {
    return a.name.localeCompare(b.name)
}

const MiningPoolsList = () => {
    const { formatMessage } = useIntl()

    const renderMiningPoolListItems = () => {
        const miningPools: {
            name: string
            url: string
            logo: string
        }[] = []
        let index = 0
        while (
            formatMessage({ id: `miningPools.list.${index}.name` }) !==
            `miningPools.list.${index}.name`
        ) {
            miningPools.push({
                name: formatMessage({ id: `miningPools.list.${index}.name` }),
                url: formatMessage({ id: `miningPools.list.${index}.url` }),
                logo: `/MiningPools/List/${formatMessage({
                    id: `miningPools.list.${index}.name`,
                })}.png`,
            })
            index += 1
        }
        return miningPools
            .sort(sortAlphabetically)
            .map((miningPool) => (
                <MiningPoolListItem
                    key={miningPool.url + miningPool.name}
                    name={miningPool.name}
                    href={miningPool.url}
                    iconPath={miningPool.logo}
                />
            ))
    }

    return (
        <section className="pt-16">
            <ContentContainer>
                <div className="grid lg:grid-cols-2 gap-5">
                    {renderMiningPoolListItems()}
                </div>
            </ContentContainer>
        </section>
    )
}

export default MiningPoolsList
