import React from "react"
import BoxWithLinearGradient from "../BoxWithLinearGradient"
import Button from "../Button"

type MiningPoolListItemProps = {
    iconPath: string
    name: string
    href: string
}

const MiningPoolListItem = ({
    iconPath,
    name,
    href,
}: MiningPoolListItemProps) => {
    return (
        <BoxWithLinearGradient>
            <div className="py-11 px-9 flex items-center h-full">
                <div className="w-[30px] md:w-[74px]">
                    <img src={`${iconPath}`} alt={name} />
                </div>
                <div className="pl-8 md:pl-11 grow text-[22px] font-bold leading-7">
                    {name}
                </div>
                <Button label="VIEW" href={href} openNewTab />
            </div>
        </BoxWithLinearGradient>
    )
}

export default MiningPoolListItem
