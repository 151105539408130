import React from "react"
import MiningPoolsHelmet from "../components/SEO/MiningPoolsHelmet"
import LearnMore from "../sections/mining-pools/LearnMore"
import MiningPoolsHero from "../sections/mining-pools/MiningPoolsHero"
import MiningPoolsList from "../sections/mining-pools/MiningPoolsList"
import ResourcesWithNewsletterForm from "../sections/mining-pools/ResourcesWithNewsletterForm"
import Layout from "../templates/Layout"

const MiningPools = () => {
    return (
        <Layout>
            <MiningPoolsHelmet />
            <div className="bg-horizen-hp-bg">
                <MiningPoolsHero />
                <LearnMore />
                <MiningPoolsList />
                <ResourcesWithNewsletterForm />
            </div>
        </Layout>
    )
}

export default MiningPools
