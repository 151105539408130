import React from "react"
import Button from "../Button"

type LearnMoreItemProps = {
    title: string
    ctaLabel: string
    ctaLink: string
}

const LearnMoreItem = ({ title, ctaLabel, ctaLink }: LearnMoreItemProps) => {
    return (
        <div className="text-white">
            <p className="font-bold text-[22px] leading-7 mb-7 max-w-[90%]">
                {title}
            </p>
            <Button label={ctaLabel} href={ctaLink} openNewTab />
        </div>
    )
}

export default LearnMoreItem
