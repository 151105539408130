import React, { ReactNodeArray } from "react"
import BoxWithLinearGradient from "./BoxWithLinearGradient"

type ResourceBox = {
    title: string | ReactNodeArray
    href: string
    desc: string
}

const ResourceBox = ({ title, href, desc }: ResourceBox) => {
    return (
        <BoxWithLinearGradient>
            <a
                className="flex flex-col items-center h-full justify-center"
                href={href}
            >
                <span className="font-bold text-[22px]">{title}</span>
                <svg
                    width="50"
                    height="1"
                    viewBox="0 0 50 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-7 mb-5"
                >
                    <line x1="50" y1="0.5" y2="0.5" stroke="white" />
                </svg>
                <p className="text-base text-horizen-content-grey text-center max-w-[130px]">
                    {desc}
                </p>
            </a>
        </BoxWithLinearGradient>
    )
}

export default ResourceBox
