import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import LearnMoreItem from "../../components/MiningPools/LearnMoreItem"
import ContentContainer from "../../templates/ContentContainer"

const LearnMore = () => {
    const { formatMessage } = useIntl()

    const renderLearnMoreItems = () => {
        const learnMoreItems = []
        let index = 0
        while (
            formatMessage({ id: `miningPools.learnMore.${index}.title` }) !==
            `miningPools.learnMore.${index}.title`
        ) {
            learnMoreItems.push(
                <LearnMoreItem
                    key={`learnMoreItem${index}`}
                    title={formatMessage({
                        id: `miningPools.learnMore.${index}.title`,
                    })}
                    ctaLabel={formatMessage({
                        id: `miningPools.learnMore.${index}.ctaLabel`,
                    })}
                    ctaLink={formatMessage({
                        id: `miningPools.learnMore.${index}.ctaLink`,
                    })}
                />
            )
            index += 1
        }
        return learnMoreItems.map((item) => item)
    }
    return (
        <section className="pt-20">
            <ContentContainer>
                <div className="grid md:grid-cols-2 gap-7">
                    {renderLearnMoreItems()}
                </div>
            </ContentContainer>
        </section>
    )
}

export default LearnMore
