import React from "react"
import { Helmet } from "react-helmet"

const MiningPoolsHelmet = () => {
    return (
        <Helmet>
            <title>ZEN Mining Pools - Earn ZEN - Horizen</title>
            <meta
                property="og:title"
                content="ZEN Mining Pools - Earn ZEN - Horizen"
            />
            <meta
                name="twitter:title"
                content="ZEN Mining Pools - Earn ZEN - Horizen"
            />
            <meta name="theme-color" content="#041742" />
            <meta
                name="description"
                content="Horizen's native cryptocurrency, ZEN, is a mineable PoW coin. Join one of our mining pools and begin earning ZEN today."
            />
            <meta
                property="og:description"
                content="Horizen's native cryptocurrency, ZEN, is a mineable PoW coin. Join one of our mining pools and begin earning ZEN today."
            />
            <meta
                name="twitter:description"
                content="Horizen's native cryptocurrency, ZEN, is a mineable PoW coin. Join one of our mining pools and begin earning ZEN today."
            />
            <meta
                property="og:image"
                content="https://www.horizen.io/assets/images/meta/miningPools.jpg"
            />
            <meta
                name="twitter:image"
                content="https://www.horizen.io/assets/images/meta/miningPools.jpg"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="keywords"
                content="zen mining pools, zen miners, zen mining"
            />
            <link rel="canonical" href="https://www.horizen.io/mining-pools/" />
            <meta
                property="og:url"
                content="https://www.horizen.io/mining-pools/"
            />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
        </Helmet>
    )
}

export default MiningPoolsHelmet
