import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import ResourceBox from "../../components/ResourceBox"
import ContentContainer from "../../templates/ContentContainer"

import * as styles from "../../styles/mining-pools/mining-pools.module.css"
import SectionHeading from "../../components/SectionHeading"

const Resources = () => {
    const { formatMessage } = useIntl()
    const renderResourcesItems = () => {
        const resourcesItems = []
        let index = 0
        while (
            formatMessage({ id: `miningPools.resources.${index}.title` }) !==
            `miningPools.resources.${index}.title`
        ) {
            resourcesItems.push(
                <ResourceBox
                    key={index}
                    title={formatMessage(
                        {
                            id: `miningPools.resources.${index}.title`,
                        },
                        { br: <br /> }
                    )}
                    desc={formatMessage({
                        id: `miningPools.resources.${index}.desc`,
                    })}
                    href={formatMessage({
                        id: `miningPools.resources.${index}.href`,
                    })}
                />
            )
            index += 1
        }
        return resourcesItems.map((item) => item)
    }
    return (
        <section>
            <ContentContainer>
                <div className="text-center mb-12">
                    <SectionHeading>
                        {formatMessage({
                            id: "miningPools.resources.sectionHeading",
                        })}
                    </SectionHeading>
                </div>
                <div
                    className={`grid lg:grid-cols-3 gap-6 auto-rows-[230px] ${styles.resource}`}
                >
                    {renderResourcesItems()}
                </div>
            </ContentContainer>
        </section>
    )
}

export default Resources
