import React from "react"
import NewsletterForm from "../../components/NewsletterForm"
import Resources from "./Resources"

import LatestUpdatesBackground from "../../assets/LatestUpdatesBackground.png"

const ResourcesWithNewsletterForm = () => {
    return (
        <div
            style={{
                backgroundImage: `url(${LatestUpdatesBackground})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "50% center",
            }}
        >
            <div className="pt-40">
                <Resources />
            </div>
            <div className="pt-40">
                <NewsletterForm />
            </div>
        </div>
    )
}

export default ResourcesWithNewsletterForm
